import { IoMdClose } from "react-icons/io";

import "./styles.css";

interface ModalVideoProps {
  videoURL: string;
  onClose: () => void;
}

export default function ModalVideo({ videoURL, onClose }: ModalVideoProps) {
  return (
    <div className="modal-video-component">
      <div className="modal-content">
        <button className="button-close" onClick={() => onClose()}>
          <IoMdClose />
        </button>
        <img src={videoURL} alt="vídeo explicativo" />
      </div>
    </div>
  );
}
