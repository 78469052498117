import { createContext, ReactNode, useEffect, useState } from "react";
import {
  AntireflectionType,
  BaseCategoriesType,
  CategoryWithBrand,
  CategoryWithFabricator,
  DesignType,
  FabricatorType,
  TechnologyType,
} from "../types/Categories";
import LensType from "../types/Lens";
import {
  addDocument,
  deleteDocument,
  getAllDocuments,
  updateDocument,
} from "../controller/firebaseController";

interface LensContextData {
  lenses: LensType[];
  antireflections: AntireflectionType[];
  brands: CategoryWithFabricator[];
  designs: DesignType[];
  families: BaseCategoriesType[];
  fabricators: FabricatorType[];
  technologies: TechnologyType[];
  refractionIndexs: BaseCategoriesType[];
  level: number;
  updateLevel: (level: number) => Promise<void>;
  setLenses: (lenses: LensType[]) => void;

  // Criacao
  createLens: (
    name: string,
    price: number,
    fabricator: string,
    brand: string,
    design: string,
    family: string,
    refractionIndex: string,
    antireflection: string,
    technology: string
  ) => Promise<void>;
  createFabricator: (name: string, available: boolean) => Promise<void>;
  createBrand: (name: string, fabricatorCod: string) => Promise<void>;
  createDesign: (
    name: string,
    brandCod: string,
    level: number,
    refractionIndexs: string[],
    description: string,
    logoURL?: string,
    designImageURL?: string,
    attributes?: string[]
  ) => Promise<void>;
  createFamily: (name: string) => Promise<void>;
  createRefractionIndex: (name: string) => Promise<void>;
  createAntireflection: (
    name: string,
    fabricatorCod: string,
    description?: string,
    videoURL?: string
  ) => Promise<void>;
  createTechnology: (
    name: string,
    fabricatorCod: string,
    description?: string,
    videoURL?: string,
    logo?: string
  ) => Promise<void>;

  // Edicao
  editFabricator: (
    id: string,
    name: string,
    available: boolean
  ) => Promise<void>;
  editBrand: (id: string, name: string, fabricatorCod: string) => Promise<void>;
  editDesign: (
    id: string,
    name: string,
    brandCod: string,
    level: number,
    refractionIndexs: string[],
    description: string,
    logoURL?: string,
    designImageURL?: string,
    attributes?: string[]
  ) => Promise<void>;
  editFamily: (id: string, name: string) => Promise<void>;
  editRefractionIndex: (id: string, name: string) => Promise<void>;
  editAntireflection: (
    id: string,
    name: string,
    fabricatorCod: string,
    description?: string,
    videoURL?: string
  ) => Promise<void>;
  editTechnology: (
    id: string,
    name: string,
    fabricatorCod: string,
    description?: string,
    videoURL?: string,
    logo?: string
  ) => Promise<void>;
  editLens: (data: LensType) => Promise<void>;

  // Exclusao
  deleteLens: (id: string) => Promise<void>;
  deleteFabricator: (id: string) => Promise<void>;
  deleteBrand: (id: string) => Promise<void>;
  deleteDesign: (id: string) => Promise<void>;
  deleteFamily: (id: string) => Promise<void>;
  deleteRefractionIndex: (id: string) => Promise<void>;
  deleteAntireflection: (id: string) => Promise<void>;
  deleteTechnology: (id: string) => Promise<void>;
}

interface LensProviderProps {
  children: ReactNode;
}

const antireflectionsColletionName = "antireflections";
const brandsColletionName = "brands";
const designsColletionName = "designs";
const familiesColletionName = "families";
const fabricatorsColletionName = "fabricators";
const technologiesColletionName = "technologies";
const refractionIndexsColletionName = "refractionIndexs";
const levelsColletionName = "levels";
const lensesColletionName = "lenses";

export const LensContext = createContext({} as LensContextData);

export function LensProvider({ children }: LensProviderProps) {
  const [antireflections, setAntireflections] = useState<AntireflectionType[]>([]);
  const [brands, setBrands] = useState<CategoryWithFabricator[]>([]);
  const [designs, setDesigns] = useState<DesignType[]>([]);
  const [families, setFamilies] = useState<BaseCategoriesType[]>([]);
  const [fabricators, setFabricators] = useState<FabricatorType[]>([]);
  const [technologies, setTecnologies] = useState<TechnologyType[]>([]);
  const [refractionIndexs, setRefractionIndexs] = useState<BaseCategoriesType[]>([]);
  const [level, setLevel] = useState({ id: "", level: 1 });
  const [lenses, setLenses] = useState<LensType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    setIsLoading(true);

    const lenses = await getAllDocuments(lensesColletionName);
    const fabricators = await getAllDocuments(fabricatorsColletionName);
    const antireflections = await getAllDocuments(antireflectionsColletionName);
    const brands = await getAllDocuments(brandsColletionName);
    const designs = await getAllDocuments(designsColletionName);
    const families = await getAllDocuments(familiesColletionName);
    const technologies = await getAllDocuments(technologiesColletionName);
    const refractionIndexs = await getAllDocuments(refractionIndexsColletionName);
    const levels = await getAllDocuments(levelsColletionName);

    setLenses(
      lenses.docs
        .map((item) => {
          const data = item.data() as LensType;
          return { ...data, id: item.id };
        })
        .sort((a, b) => (a.fabricator > b.fabricator ? 1 : -1))
    );

    setFabricators(
      fabricators.docs
        .map((item) => {
          const data = item.data() as FabricatorType;
          return { ...data, id: item.id, available: data.available };
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );

    setAntireflections(
      antireflections.docs
        .map((item) => {
          const data = item.data() as AntireflectionType;
          return { ...data, id: item.id };
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );

    setBrands(
      brands.docs
        .map((item) => {
          const data = item.data() as CategoryWithFabricator;
          return { ...data, id: item.id };
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );

    setDesigns(
      designs.docs
        .map((item) => {
          const data = item.data() as DesignType;
          return {
            ...data,
            id: item.id,
            refractionIndexs: data.refractionIndexs ?? [],
            description: data.description ?? "",
          };
        })
        .sort((a, b) =>
          a ? (a.name.toLocaleLowerCase() > b.name.toLowerCase() ? 1 : -1) : 0
        )
    );

    setFamilies(
      families.docs
        .map((item) => {
          const data = item.data() as CategoryWithBrand;
          return { ...data, id: item.id };
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );

    setTecnologies(
      technologies.docs
        .map((item) => {
          const data = item.data() as TechnologyType;
          return { ...data, id: item.id };
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );

    setRefractionIndexs(
      refractionIndexs.docs
        .map((item) => {
          const data = item.data() as BaseCategoriesType;
          return { ...data, id: item.id };
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );

    if (levels.docs.length > 0) {
      setLevel({
        id: levels.docs[0].id,
        level: levels.docs[0].data().level as number,
      });
    }

    setIsLoading(false);
  }

  // Funcoes de criacao

  async function createLens(
    name: string,
    price: number,
    fabricator: string,
    brand: string,
    design: string,
    family: string,
    refractionIndex: string,
    antireflection: string,
    technology: string
  ) {
    await addDocument(lensesColletionName, {
      name,
      price,
      fabricator,
      brand,
      design,
      family,
      refractionIndex,
      antireflection,
      technology,
    });
    loadData(); // Recarrega os dados após a criação
  }

  async function createFabricator(name: string, available: boolean) {
    await addDocument(fabricatorsColletionName, { name, available });
    loadData(); // Recarrega os dados após a criação
  }

  async function createBrand(name: string, fabricatorCod: string) {
    await addDocument(brandsColletionName, { name, fabricatorCod });
    loadData(); // Recarrega os dados após a criação
  }

  async function createDesign(
    name: string,
    brandCod: string,
    level: number,
    refractionIndexs: string[],
    description: string,
    logoURL?: string,
    designImageURL?: string,
    attributes?: string[]
  ) {
    await addDocument(designsColletionName, {
      name,
      brandCod,
      level,
      refractionIndexs,
      description,
      logoURL: logoURL ?? "",
      designImageURL: designImageURL ?? "",
      attributes: attributes ?? [],
    });
    loadData(); // Recarrega os dados após a criação
  }

  async function createFamily(name: string) {
    await addDocument(familiesColletionName, { name });
    loadData(); // Recarrega os dados após a criação
  }

  async function createRefractionIndex(name: string) {
    await addDocument(refractionIndexsColletionName, { name });
    loadData(); // Recarrega os dados após a criação
  }

  async function createAntireflection(
    name: string,
    fabricatorCod: string,
    description?: string,
    videoURL?: string
  ) {
    await addDocument(antireflectionsColletionName, {
      name,
      fabricatorCod,
      description: description ?? "",
      videoURL: videoURL ?? "",
    });
    loadData(); // Recarrega os dados após a criação
  }

  async function createTechnology(
    name: string,
    fabricatorCod: string,
    description?: string,
    videoURL?: string,
    logo?: string
  ) {
    await addDocument(technologiesColletionName, {
      name,
      fabricatorCod,
      description: description ?? "",
      videoURL: videoURL ?? "",
      logo: logo ?? "",
    });
    loadData(); // Recarrega os dados após a criação
  }

  // Atualizar nivel

  async function updateLevel(newLevel: number) {
    return await updateDocument(levelsColletionName, level.id, { level: newLevel });
  }

  // Funcoes de edicao

  async function editLens({
    id,
    cod,
    name,
    price,
    fabricator,
    brand,
    design,
    family,
    refractionIndex,
    antireflection,
    technology,
  }: LensType) {
    await updateDocument(lensesColletionName, id, {
      cod,
      name,
      price,
      fabricator,
      brand,
      design,
      family,
      refractionIndex,
      antireflection,
      technology,
    });

    // Atualize o estado do contexto com o item específico alterado
    setLenses((prevLenses) =>
      prevLenses.map((lens) =>
        lens.id === id
          ? {
              id,
              cod,
              name,
              price,
              fabricator,
              brand,
              design,
              family,
              refractionIndex,
              antireflection,
              technology,
            }
          : lens
      )
    );
  }

  async function editFabricator(id: string, name: string, available: boolean) {
    await updateDocument(fabricatorsColletionName, id, { name, available });

    setFabricators((prevFabricators) =>
      prevFabricators.map((fabricator) =>
        fabricator.id === id ? { ...fabricator, name, available } : fabricator
      )
    );
  }

  async function editBrand(id: string, name: string, fabricatorCod: string) {
    await updateDocument(brandsColletionName, id, { name, fabricatorCod });

    setBrands((prevBrands) =>
      prevBrands.map((brand) =>
        brand.id === id ? { ...brand, name, fabricatorCod } : brand
      )
    );
  }

  async function editDesign(
    id: string,
    name: string,
    brandCod: string,
    level: number,
    refractionIndexs: string[],
    description: string,
    logoURL?: string,
    designImageURL?: string,
    attributes?: string[]
  ) {
    await updateDocument(designsColletionName, id, {
      name,
      brandCod,
      level,
      refractionIndexs,
      description,
      logoURL: logoURL ?? "",
      designImageURL: designImageURL ?? "",
      attributes: attributes ?? [],
    });

    setDesigns((prevDesigns) =>
      prevDesigns.map((design) =>
        design.id === id
          ? {
              id,
              name,
              brandCod,
              level,
              refractionIndexs,
              description,
              logoURL: logoURL ?? "",
              designImageURL: designImageURL ?? "",
              attributes: attributes ?? [],
            }
          : design
      )
    );
  }

  async function editFamily(id: string, name: string) {
    await updateDocument(familiesColletionName, id, { name });

    setFamilies((prevFamilies) =>
      prevFamilies.map((family) => (family.id === id ? { ...family, name } : family))
    );
  }

  async function editRefractionIndex(id: string, name: string) {
    await updateDocument(refractionIndexsColletionName, id, { name });

    setRefractionIndexs((prevRefractionIndexs) =>
      prevRefractionIndexs.map((refractionIndex) =>
        refractionIndex.id === id ? { ...refractionIndex, name } : refractionIndex
      )
    );
  }

  async function editAntireflection(
    id: string,
    name: string,
    fabricatorCod: string,
    description?: string,
    videoURL?: string
  ) {
    await updateDocument(antireflectionsColletionName, id, {
      name,
      fabricatorCod,
      description: description ?? "",
      videoURL: videoURL ?? "",
    });

    setAntireflections((prevAntireflections) =>
      prevAntireflections.map((antireflection) =>
        antireflection.id === id
          ? { ...antireflection, name, fabricatorCod, description, videoURL }
          : antireflection
      )
    );
  }

  async function editTechnology(
    id: string,
    name: string,
    fabricatorCod: string,
    description?: string,
    videoURL?: string,
    logo?: string
  ) {
    await updateDocument(technologiesColletionName, id, {
      name,
      fabricatorCod,
      description: description ?? "",
      videoURL: videoURL ?? "",
      logo: logo ?? "",
    });

    setTecnologies((prevTechnologies) =>
      prevTechnologies.map((technology) =>
        technology.id === id
          ? { ...technology, name, fabricatorCod, description, videoURL, logo }
          : technology
      )
    );
  }

  // Funcoes de exclusao

  async function deleteLens(id: string) {
    await deleteDocument(lensesColletionName, id);
    setLenses((prevLenses) => prevLenses.filter((lens) => lens.id !== id));
  }

  async function deleteFabricator(id: string) {
    await deleteDocument(fabricatorsColletionName, id);
    setFabricators((prevFabricators) =>
      prevFabricators.filter((fabricator) => fabricator.id !== id)
    );
  }

  async function deleteBrand(id: string) {
    await deleteDocument(brandsColletionName, id);
    setBrands((prevBrands) => prevBrands.filter((brand) => brand.id !== id));
  }

  async function deleteDesign(id: string) {
    await deleteDocument(designsColletionName, id);
    setDesigns((prevDesigns) => prevDesigns.filter((design) => design.id !== id));
  }

  async function deleteFamily(id: string) {
    await deleteDocument(familiesColletionName, id);
    setFamilies((prevFamilies) => prevFamilies.filter((family) => family.id !== id));
  }

  async function deleteRefractionIndex(id: string) {
    await deleteDocument(refractionIndexsColletionName, id);
    setRefractionIndexs((prevRefractionIndexs) =>
      prevRefractionIndexs.filter((refractionIndex) => refractionIndex.id !== id)
    );
  }

  async function deleteAntireflection(id: string) {
    await deleteDocument(antireflectionsColletionName, id);
    setAntireflections((prevAntireflections) =>
      prevAntireflections.filter((antireflection) => antireflection.id !== id)
    );
  }

  async function deleteTechnology(id: string) {
    await deleteDocument(technologiesColletionName, id);
    setTecnologies((prevTechnologies) =>
      prevTechnologies.filter((technology) => technology.id !== id)
    );
  }

  return (
    <LensContext.Provider
      value={{
        lenses,
        antireflections,
        brands,
        designs,
        families,
        fabricators,
        technologies,
        refractionIndexs,
        level: level.level,
        setLenses,
        createLens,
        createAntireflection,
        createBrand,
        createDesign,
        createFabricator,
        createFamily,
        createRefractionIndex,
        createTechnology,
        updateLevel,
        editLens,
        editAntireflection,
        editBrand,
        editDesign,
        editFabricator,
        editFamily,
        editRefractionIndex,
        editTechnology,
        deleteAntireflection,
        deleteBrand,
        deleteDesign,
        deleteFabricator,
        deleteFamily,
        deleteLens,
        deleteRefractionIndex,
        deleteTechnology,
      }}
    >
      {!isLoading && children}
    </LensContext.Provider>
  );
}
