import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import Login from "./pages/Login"
import Home from "./pages/Home"
import EditClient from "./pages/edit/client"
import AdminHome from "./pages/admin"
import AdminConsultant from "./pages/admin/register/consultant"
import PrescriptionPage from "./pages/questionary/PrescriptionPage"
import VisionUsePage from "./pages/questionary/VisionUsePage"
import UseOfScreensPage from "./pages/questionary/UseOfScreensPage"
import OthersInfoPage from "./pages/questionary/OthersInfoPage"
import SunLightPage from "./pages/questionary/SunLightPage"
import Refraction from "./pages/questionary/RefractionPage"
import Technology from "./pages/questionary/TechnologyPage"
import Antireflection from "./pages/questionary/AntireflectionPage"
import SecondPair from "./pages/questionary/SecondPairPage"
import ContactLenses from "./pages/questionary/ContactLensesPage"
import Congratulations from "./pages/questionary/CongratulationsPage"
import PageNotFound from "./pages/404"
import ConsultantReport from "./pages/reports"
import PrivateRoute from "./components/PrivateRoute"
import PrivateRouteQuestionary from "./components/PrivateRouteQuestionary"
import PrivateRouteAdmin from "./components/PrivateRouteAdmin"
import RegisterFabricators from "./pages/admin/register/fabricators"
import RegisterBrands from "./pages/admin/register/brands"
import RegisterAntireflections from "./pages/admin/register/antireflections"
import RegisterFamily from "./pages/admin/register/family"
import RegisterRefractionIndex from "./pages/admin/register/refractionindexs"
import RegisterShops from "./pages/admin/register/shops"
import RegisterTechnology from "./pages/admin/register/technologies"
import RegisterLenses from "./pages/admin/register/lenses"
import RegisterDesign from "./pages/admin/register/design"
import RegisterLevel from "./pages/admin/register/level"
import Reports from "./pages/admin/reports"
import PrescriptionResumePage from "./pages/questionary/PrescriptionResumePage"
import DesignPage from "./pages/questionary/DesignPage"
import DocumentPDF from "./components/DocumentPDF"
import ReportPDF from "./pages/reports/pdf"
import RegisterDesignAttributes from "./pages/admin/register/designAttributes"
import { useEffect } from "react"
import Monitoring from "./pages/admin/register/monitoring"
import { analytics } from "./services/analytics"
import { logEvent } from "firebase/analytics"
import AtualizarPrices from "./pages/admin/register/attprices"

const RoutesListener = () => {
  const location = useLocation()

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: location.pathname,
      firebase_screen_class: 'SomeComponent'
    });
  }, [location]);

  return <></>;
}

export default function AllRoutes() {
  return (
    <BrowserRouter>
      <RoutesListener />
      <Routes>
        {/* Login */}
        <Route path="/login" element={<Login />} />

        {/* Consultor */}
        {/* <PrivateRoute component={Home} path="/" exact /> */}

        <Route path="/" element={<PrivateRoute />}>
          <Route path="" element={<Home />} />
        </Route>

        <Route path="/edit/client" element={<PrivateRoute />}>
          <Route path="" element={<EditClient />} />
        </Route>

        <Route path="/consultant/reports" element={<PrivateRoute />}>
          <Route path="" element={<ConsultantReport />} />
        </Route>
        
        <Route path="/consultant/reports/pdf/:id" element={<PrivateRoute />}>
          <Route path="" element={<ReportPDF />} />
        </Route>

        {/* Admin */}
        <Route path="/admin" element={<PrivateRouteAdmin />}>
          <Route path="" element={<AdminHome />} />
        </Route>

        <Route path="/admin/consultant" element={<PrivateRouteAdmin />}>
          <Route path="" element={<AdminConsultant />} />
        </Route>

        <Route path="/admin/reports" element={<PrivateRouteAdmin />}>
          <Route path="" element={<Reports />} />
        </Route>

        <Route path="/admin/register/fabricator" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterFabricators />} />
        </Route>

        <Route path="/admin/register/brand" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterBrands />} />
        </Route>

        <Route path="/admin/register/antireflection" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterAntireflections />} />
        </Route>

        <Route path="/admin/register/design" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterDesign />} />
        </Route>

        <Route path="/admin/register/design-attributes" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterDesignAttributes />} />
        </Route>

        <Route path="/admin/register/family" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterFamily />} />
        </Route>

        <Route path="/admin/register/attprices" element={<PrivateRouteAdmin />}>
          <Route path="" element={<AtualizarPrices/>} />
        </Route>


        <Route path="/admin/register/index" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterRefractionIndex />} />
        </Route>

        <Route path="/admin/register/shop" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterShops />} />
        </Route>

        <Route path="/admin/register/technology" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterTechnology />} />
        </Route>

        <Route path="/admin/register/lens" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterLenses />} />
        </Route>

        <Route path="/admin/register/level" element={<PrivateRouteAdmin />}>
          <Route path="" element={<RegisterLevel />} />
        </Route>

        <Route path="/admin/register/monitoring" element={<PrivateRouteAdmin />}>
          <Route path="" element={<Monitoring />} />
        </Route>

        {/* Questionário */}
        <Route path="/questionary/prescription" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<PrescriptionPage />} />
        </Route>

        <Route path="/questionary/prescription/resume" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<PrescriptionResumePage />} />
        </Route>

        <Route path="/questionary/vision-use" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<VisionUsePage />} />
        </Route>

        <Route path="/questionary/use-of-screens" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<UseOfScreensPage />} />
        </Route>

        <Route path="/questionary/others-info" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<OthersInfoPage />} />
        </Route>
        
        <Route path="/questionary/sun-light" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<SunLightPage />} />
        </Route>
        
        <Route path="/design" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<DesignPage />} />
        </Route>

        <Route path="/refraction" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<Refraction />} />
        </Route>
        
        <Route path="/technology" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<Technology />} />
        </Route>

        <Route path="/antireflection" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<Antireflection />} />
        </Route>

        <Route path="/secondpair" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<SecondPair />} />
        </Route>

        <Route path="/contactlenses" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<ContactLenses />} />
        </Route>
      
        <Route path="/congratulations" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<Congratulations />} />
        </Route>

        <Route path="/questionary/pdf/:id" element={<PrivateRouteQuestionary />}>
          <Route path="" element={<DocumentPDF />} />
        </Route>

        <Route element={<PageNotFound />} path="*" />
      </Routes>
    </BrowserRouter>
  )
}
