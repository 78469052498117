import { FormEvent, useContext, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import FormButton from "../../../../components/FormButton";
import Header from "../../../../components/Header";
import FormInput from "../../../../components/FormInput";
import BoxError from "../../../../components/BoxError";
import BoxSuccess from "../../../../components/BoxSuccess";
import FormSelect from "../../../../components/FormSelect";
import { LensContext } from "../../../../contexts/lensContext";
import ListItem from "../../../../components/ListItems";
import ModalDelete from "../../../../components/ModalDelete";
import { DesignType } from "../../../../types/Categories";
import FormInputImage from "../../../../components/FormInputImage";
import FormRadioCheck from "../../../../components/FormRadioCheck";
import { DesignAttributesContext } from "../../../../contexts/designAttributesContext";

import "./styles.css";

export default function RegisterDesign() {
  const navigate = useNavigate();

  const {
    brands,
    refractionIndexs,
    createDesign,
    editDesign,
    deleteDesign,
    designs,
    level,
  } = useContext(LensContext);

  const { designAttributes } = useContext(DesignAttributesContext);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const [levelSelected, setLevelSelected] = useState(1);
  const [brandSelected, setBrandSelected] = useState("");
  const [designImageURL, setDesignImageURL] = useState("");
  const [attributes, setAttributes] = useState<string[]>([]);

  const [refractionIndexGroup1, setRefractionIndexGroup1] =
    useState<string>("");
  const [refractionIndexGroup2, setRefractionIndexGroup2] =
    useState<string>("");
  const [refractionIndexGroup3, setRefractionIndexGroup3] =
    useState<string>("");
  const [refractionIndexGroup4, setRefractionIndexGroup4] =
    useState<string>("");

  const [inputError, setInputError] = useState<string[]>([]);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [itemEdit, setItemEdit] = useState<DesignType>();

  const [idToDelete, setIdToDelete] = useState<string>();

  function onRegister(event: FormEvent) {
    event.preventDefault();

    event.currentTarget.scrollTo({ top: 0, behavior: "smooth" });

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (brandSelected.trim() === "") {
      setInputError(["Tem que escolher uma marca"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (designs.filter((item) => item.name === name).length > 0) {
      setInputError(["Esse desenho já está cadastrado"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    createDesign(
      name,
      brandSelected,
      levelSelected,
      [
        refractionIndexGroup1,
        refractionIndexGroup2,
        refractionIndexGroup3,
        refractionIndexGroup4,
      ],
      description,
      logoURL,
      designImageURL,
      attributes
    )
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => setRegisterSuccess(false), 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onEdit(event: FormEvent) {
    event.preventDefault();

    if (!itemEdit) {
      return;
    }

    event.currentTarget.scrollTo({ top: 0, behavior: "smooth" });

    const id = itemEdit.id;
    const name = itemEdit.name;
    const description = itemEdit.description;
    const logoURL = itemEdit.logoURL;
    const brandSelected = itemEdit.brandCod;
    const levelSelected = itemEdit.level;
    const refractionIndexs = itemEdit.refractionIndexs.map((item) =>
      !item ? "" : item
    );
    const designImageURL = itemEdit.designImageURL;
    const attributes = itemEdit.attributes;

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (brandSelected.trim() === "") {
      setInputError(["Tem que escolher uma marca"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (
      designs.filter(
        (item) =>
          item.name === name &&
          item.brandCod === brandSelected &&
          item.id !== id
      ).length > 0
    ) {
      setInputError(["Esse desenho já está cadastrado"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }
    editDesign(
      id,
      name,
      brandSelected,
      levelSelected,
      refractionIndexs,
      description,
      logoURL,
      designImageURL,
      attributes
    )
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => {
          setItemEdit(undefined);
          setRegisterSuccess(false);
        }, 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onDelete(event: FormEvent) {
    event.preventDefault();

    if (!idToDelete) {
      return;
    }

    setItemEdit(undefined);

    deleteDesign(idToDelete)
      .then(() => {
        setRegisterSuccess(true);
        setIdToDelete(undefined);
        setTimeout(() => {
          setRegisterSuccess(false);
        }, 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function goBack() {
    navigate("/admin");
  }

  return (
    <div className="register-design-page">
      <Header>
        <button className="logout-button" onClick={() => goBack()} title="Sair">
          <BsArrowLeftShort className="icon" size={30} />
        </button>
      </Header>

      <main>
        {!itemEdit ? (
          <form id="form-edit" className="form-register" onSubmit={onRegister}>
            <h2 className="title-form">Cadastrar desenho</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Desenho cadastrado com sucesso`} />
            )}

            <FormInput
              label="Nome"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />

            <FormInput
              label="Descrição"
              required
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />

            <FormInputImage
              label="Logo"
              value={logoURL}
              placeholder="Logo desse desenho"
              onChange={(event) => setLogoURL(event.target.value)}
            />

            <FormInputImage
              label="Desenho"
              value={designImageURL}
              placeholder="Imagem desse desenho"
              onChange={(event) => setDesignImageURL(event.target.value)}
            />

            <FormSelect
              label="Nível"
              required
              options={new Array(level).fill(1).map((_, index) => {
                return { label: String(index + 1), value: String(index + 1) };
              })}
              value={levelSelected.toString()}
              onChange={(value) =>
                value && setLevelSelected(Number(value.value))
              }
            />

            <FormSelect
              label="Marca"
              required
              options={brands.map((item) => {
                return { label: item.name, value: item.id };
              })}
              value={brandSelected}
              onChange={(value) => value && setBrandSelected(value.value)}
            />

            <FormSelect
              label="Índice do grupo 1"
              required
              options={refractionIndexs
                .filter((item) => Number(item.name) <= 1.56)
                .map((item) => {
                  return { label: item.name, value: item.name };
                })}
              value={refractionIndexGroup1}
              onChange={(value) =>
                value && setRefractionIndexGroup1(value.value)
              }
            />

            <FormSelect
              label="Índice do grupo 2"
              required
              options={refractionIndexs
                .filter(
                  (item) =>
                    Number(item.name) > 1.56 && Number(item.name) <= 1.61
                )
                .map((item) => {
                  return { label: item.name, value: item.name };
                })}
              value={refractionIndexGroup2}
              onChange={(value) =>
                value && setRefractionIndexGroup2(value.value)
              }
            />

            <FormSelect
              label="Índice do grupo 3"
              required
              options={refractionIndexs
                .filter(
                  (item) =>
                    Number(item.name) > 1.61 && Number(item.name) <= 1.67
                )
                .map((item) => {
                  return { label: item.name, value: item.name };
                })}
              value={refractionIndexGroup3}
              onChange={(value) =>
                value && setRefractionIndexGroup3(value.value)
              }
            />

            <FormSelect
              label="Índice do grupo 4"
              required
              options={refractionIndexs
                .filter((item) => Number(item.name) > 1.67)
                .map((item) => {
                  return { label: item.name, value: item.name };
                })}
              value={refractionIndexGroup4}
              onChange={(value) =>
                value && setRefractionIndexGroup4(value.value)
              }
            />

            <FormRadioCheck
              label="Atributos"
              options={designAttributes.map((item) => item.id)}
              optionsSelected={attributes}
              onToggle={(item) =>
                setAttributes(
                  attributes.includes(item)
                    ? attributes.filter((att) => att !== item)
                    : attributes.concat(item)
                )
              }
            />

            <FormButton children="OK" type="submit" />
          </form>
        ) : (
          <form id="form-register" className="form-register" onSubmit={onEdit}>
            <h2 className="title-form">Editar desenho</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Desenho editado com sucesso`} />
            )}

            <FormInput
              label="Nome"
              required
              value={itemEdit.name}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, name: event.target.value })
              }
            />

            <FormInput
              label="Descrição"
              required
              value={itemEdit.description}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, description: event.target.value })
              }
            />

            <FormInputImage
              label="Logo"
              value={itemEdit.logoURL}
              placeholder="Logo desse desenho"
              onChange={(event) =>
                setItemEdit({ ...itemEdit, logoURL: event.target.value })
              }
            />

            <FormInputImage
              label="Desenho"
              value={itemEdit.designImageURL}
              placeholder="Imagem desse desenho"
              onChange={(event) =>
                setItemEdit({ ...itemEdit, designImageURL: event.target.value })
              }
            />

            <FormSelect
              label="Nível"
              required
              options={new Array(level).fill(1).map((_, index) => {
                return { label: String(index + 1), value: String(index + 1) };
              })}
              value={itemEdit.level.toString()}
              onChange={(value) =>
                value &&
                setItemEdit({ ...itemEdit, level: Number(value.value) })
              }
            />

            <FormSelect
              label="Marca"
              required
              options={brands.map((item) => {
                return { label: item.name, value: item.id };
              })}
              value={itemEdit.brandCod}
              onChange={(value) =>
                value && setItemEdit({ ...itemEdit, brandCod: value.value })
              }
            />

            <FormSelect
              label="Índice do grupo 1"
              required
              options={refractionIndexs
                .filter((item) => Number(item.name) <= 1.56)
                .map((item) => {
                  return { label: item.name, value: item.name };
                })}
              value={itemEdit.refractionIndexs[0] ?? ""}
              onChange={(value) =>
                value &&
                setItemEdit({
                  ...itemEdit,
                  refractionIndexs: [
                    value.value,
                    itemEdit.refractionIndexs[1],
                    itemEdit.refractionIndexs[2],
                    itemEdit.refractionIndexs[3],
                  ],
                })
              }
            />

            <FormSelect
              label="Índice do grupo 2"
              required
              options={refractionIndexs
                .filter(
                  (item) =>
                    Number(item.name) > 1.56 && Number(item.name) <= 1.61
                )
                .map((item) => {
                  return { label: item.name, value: item.name };
                })}
              value={itemEdit.refractionIndexs[1] ?? ""}
              onChange={(value) =>
                value &&
                setItemEdit({
                  ...itemEdit,
                  refractionIndexs: [
                    itemEdit.refractionIndexs[0],
                    value.value,
                    itemEdit.refractionIndexs[2],
                    itemEdit.refractionIndexs[3],
                  ],
                })
              }
            />

            <FormSelect
              label="Índice do grupo 3"
              required
              options={refractionIndexs
                .filter(
                  (item) =>
                    Number(item.name) > 1.61 && Number(item.name) <= 1.67
                )
                .map((item) => {
                  return { label: item.name, value: item.name };
                })}
              value={itemEdit.refractionIndexs[2] ?? ""}
              onChange={(value) =>
                value &&
                setItemEdit({
                  ...itemEdit,
                  refractionIndexs: [
                    itemEdit.refractionIndexs[0],
                    itemEdit.refractionIndexs[1],
                    value.value,
                    itemEdit.refractionIndexs[3],
                  ],
                })
              }
            />

            <FormSelect
              label="Índice do grupo 4"
              required
              options={refractionIndexs
                .filter((item) => Number(item.name) > 1.67)
                .map((item) => {
                  return { label: item.name, value: item.name };
                })}
              value={itemEdit.refractionIndexs[3] ?? ""}
              onChange={(value) =>
                value &&
                setItemEdit({
                  ...itemEdit,
                  refractionIndexs: [
                    itemEdit.refractionIndexs[0],
                    itemEdit.refractionIndexs[1],
                    itemEdit.refractionIndexs[2],
                    value.value,
                  ],
                })
              }
            />

            <FormRadioCheck
              label="Atributos"
              options={designAttributes.map((item) => item.id)}
              optionsSelected={itemEdit.attributes ?? []}
              onToggle={(item) => {
                setItemEdit({
                  ...itemEdit,
                  attributes: itemEdit.attributes
                    ? itemEdit.attributes?.includes(item)
                      ? itemEdit.attributes?.filter((att) => att !== item)
                      : itemEdit.attributes?.concat(item)
                    : [item],
                });
              }}
            />

            <FormButton children="OK" type="submit" />
            <FormButton
              children="Cancelar"
              type="button"
              onClick={() => setItemEdit(undefined)}
            />
          </form>
        )}

        {idToDelete && (
          <ModalDelete
            nameItem={
              designs.find((item) => item.id === idToDelete)?.name ?? ""
            }
            onDelete={onDelete}
            onCancel={() => setIdToDelete(undefined)}
          />
        )}

        <ListItem
          render={designs.map((item) => {
            return {
              titles: ["Logo", "Desenho", "Marca", "Nível", "Índices"],
              item: [
                item.logoURL ?? "",
                item.name,
                brands.find((brand) => brand.id === item.brandCod)?.name ?? "",
                String(item.level),
                item.refractionIndexs.filter((item) => item !== "").join(", "),
              ],
              onEdit: () => {
                setItemEdit(item);
              },
              onDelete: () => {
                setIdToDelete(item.id);
              },
            };
          })}
        />
      </main>
    </div>
  );
}
