import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import birthDateForAge from "../../../utils/birthDateForAge";
import { FaEye } from "react-icons/fa";
import { RiArrowDownSLine } from "react-icons/ri";
import { FiBookOpen, FiSun } from "react-icons/fi";
import { BiGlasses } from "react-icons/bi";
import { AiFillAlert } from "react-icons/ai";
import { MdComputer } from "react-icons/md";
import LensType from "../../../types/Lens";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import { ClientContext } from "../../../contexts/clientContext";
import { ResponseContext } from "../../../contexts/responseContext";
import { LoginContext } from "../../../contexts/loginContext";

import "./styles.css";

export default function CongratulationsPage() {
  const navigate = useNavigate();

  const {
    getHighlights,
    prescription,
    frames,
    switchedContactLens,
    switchedSecondPair,
    getLens,
  } = useContext(QuestionaryContext);

  const { createResponse } = useContext(ResponseContext);

  const { client } = useContext(ClientContext);

  const { currentUser } = useContext(LoginContext);

  const [transition, setTransition] = useState(true);

  const [lens, setLens] = useState<LensType>();
  const [image, setImage] = useState("");
  const [highlights, setHighlights] = useState<string[]>([]);

  const [isLoading] = useState(false);

  const details = [
    {
      name: "Altas Dioptrias",
      icon: <BiGlasses />,
      description:
        "Nas lentes com altas dioptrias é normal que os efeitos “olho de boi” para lentes positivas e “fundo de garrafa” para lentes negativas aconteça. Além disso, as aberrações laterais ficam mais evidentes.\n Para minimizar esses problemas é indicado a escolha de lentes com altos índices de refração e em caso de multifocais, um melhor corredor progressivo vai acelerar muito a adaptação e melhorar o conforto do usuário.",
    },
    {
      name: "Muito tempo em atividades laborais",
      icon: <FiBookOpen />,
      description:
        "Você passa muito tempo utilizando a visão intermediária e perto. Usuários com essa demanda, costumam sentir desconforto postural por terem que ficar procurando o campo de visão ideal em cada atividade realizada.\n Para um maior conforto é indicado o uso de lentes ocupacionais, que priorizam a visão intermediária facilitando a transição entre essas duas distâncias. Ideal para pessoas que passam muito tempo em escritórios, artesanato, estudando, tocando instrumento, etc.\n Em caso de uma demanda muito grande somente pela visão de perto (até 33 cm), é indicado lentes somente para perto, ao invés da ocupacional.",
    },
    {
      name: "Alta exposição à luz azul",
      icon: <MdComputer />,
      description:
        "Excesso de luz nas faixas ultravioleta e azul violeta pode causar lesões no olho humano. Além de causar inflamação dolorosa da conjuntiva e da córnea, ela pode produzir lesões no cristalino do olho (p. ex.: catarata) e, sobretudo, na retina (degeneração macular).\n Pessoas com exposição excessiva, seja através de telas (fontes artificiais), seja através do sol (fonte natural), devem usar lentes com proteção contra a luz azul para evitar os prejuízos acima citados que na maioria das vezes, só são notados quando já é tarde demais, vez que os efeitos são cumulativos.",
    },
    {
      name: "Exposição à luz azul",
      icon: <MdComputer />,
      description:
        "Excesso de luz nas faixas ultravioleta e azul violeta pode causar lesões no olho humano. Além de causar inflamação dolorosa da conjuntiva e da córnea, ela pode produzir lesões no cristalino do olho (p. ex.: catarata) e, sobretudo, na retina (degeneração macular).\n Pessoas com exposição excessiva, seja através de telas (fontes artificiais), seja através do sol (fonte natural), devem usar lentes com proteção contra a luz azul para evitar os prejuízos acima citados que na maioria das vezes, só são notados quando já é tarde demais, vez que os efeitos são cumulativos.",
    },
    {
      name: "Alta Exposição aos raios UV",
      icon: <FiSun />,
      description:
        "A luz UVB é conhecida por causar vermelhidão e pode aumentar o risco de ter câncer de pele. Uma vermelhidão é um sinal que o DNA das células da sua pele foram danificadas pela radiação UV. Se o dano no seu DNA aumenta com o tempo, pode levar ao câncer de pele.\n Algumas pessoas têm maior risco que outras, como por exemplo pessoas com pele e olhos claros. No entanto, ficar com uma vermelhidão não significa que irá com certeza desenvolvê-lo, mas deve sempre ter cuidado.\n A luz UV pode também ter um efeito prejudicial sobre os olhos, uma vez que, ao contrário da pele, as barreiras dos olhos a esta radiação é mínima, como mencionado anteriormente. A exposição excessiva aos raios UV é uma das principais causas de problemas como as cataratas, fotoqueratite, pterígio e pinguécula.",
    },
    {
      name: "Alto grau de fotofobia",
      icon: <FaEye />,
      description:
        "A fotofobia é uma condição visual que se traduz numa sensibilidade à luz quando os olhos estão expostos à claridade. Pode experienciar sensibilidade quando exposto a uma luz muito forte, como o sol, ou a uma luz incandescente. A fonte de luz pode ser natural ou artificial.\n É indicado o uso de lentes fotossensíveis e/ou óculos solares sempre que estiver em ambientes externos, mesmo que em dia nublado.",
    },
  ];

  useEffect(() => {
    setTransition(false);
    selectPeopleImage(
      birthDateForAge(client?.birth ?? "1990-01-01"),
      client ? client.gender : "feminino"
    );
    setHighlights(getHighlights());
    setLens(getLens());
    // eslint-disable-next-line
  }, []);

  function selectPeopleImage(age: number, gender: "feminino" | "masculino") {
    let ageRange;

    if (age < 5) ageRange = 3;
    // else if (age < 10) ageRange = 7;
    // else if (age < 18) ageRange = 15;
    else if (age >= 20) ageRange = 20;
    else if (age < 35) ageRange = 20;
    // else if (age < 50) ageRange = 40;
    // else if (age < 70) ageRange = 60;
    else ageRange = 20;
    setImage(`../../assets/${gender}-${ageRange}-anos.png`);
    console.log(`${gender} ${ageRange}`);
  }

  async function onFinish() {
    if (client && currentUser && lens) {
      const id = await createResponse(
        client.cpf,
        currentUser.email,
        highlights,
        lens.id,
        frames,
        switchedContactLens,
        switchedSecondPair.join(", "),
        prescription
      );

      navigate(`/questionary/pdf/${id}`);

      window.open(
        `https://api.whatsapp.com/send?phone=+55${client.phone}&text=Olá, ${client.name}!`
      );
    }
  }

  function returnToDesignRecommendation() {
    navigate("/design");
  }

  return (
    <div className="congratulations-page">
      <main className={transition ? "transition" : ""}>
        <div className="header-title">
          <h2 className="title">Parabéns!</h2>
          <img
            src="/assets/logos/MARCA_HORIZONTAL_01.svg"
            alt="My Best Vision"
            className="logo"
          />
        </div>

        <div className="resume">
          <div
            className={
              highlights.length > 0 ? "raio-x" : "raio-x not-highlights"
            }
          >
            <img src={image} alt="Pessoa de óculos" />

            {highlights.length > 0 && (
              <div className="highlights">
                <h2>
                  Pontos importantes
                  <AiFillAlert color="#990000" />
                </h2>
                <div className="grid-highlights">
                  {details
                    .filter((item) => highlights.includes(item.name))
                    .map((item) => (
                      <div>
                        <div className="item" key={item.name}>
                          {item.icon}
                          <p>{item.name}</p>
                        </div>

                        <div className="explanation">
                          {item.description.split("\n").map((p) => (
                            <p>{p}</p>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>

          <span className="big-separator">
            <RiArrowDownSLine size={50} />
          </span>

          <div className="infos">
            <div className="personal-info">
              <h2>Informações pessoais</h2>

              <strong className="title">Nome</strong>
              <p className="content">{client?.name}</p>

              <strong className="title">Idade</strong>
              <p className="content">
                {client && birthDateForAge(client.birth)} anos
              </p>

              <h2>Receita</h2>

              <div className="grid-prescription">
                <span className="header"></span>
                <span className="header">Esférico</span>
                <span className="header">Cilindro</span>
                <span className="header">Eixo</span>

                <span>OD</span>
                <span>{prescription?.rightEye.esf}</span>
                <span>{prescription?.rightEye.cil || "-"}</span>
                <span>{prescription?.rightEye.eixo || "-"}</span>

                <span>OE</span>
                <span>{prescription?.leftEye.esf}</span>
                <span>{prescription?.leftEye.cil || "-"}</span>
                <span>{prescription?.leftEye.eixo || "-"}</span>

                <span></span>
                <span></span>
                <span>Adição</span>
                <span>{prescription?.adition || "-"}</span>
              </div>
            </div>

            {lens && (
              <>
                <span className="separator"></span>

                <div className="lens-info">
                  <h2>Informações sobre a lente</h2>

                  <strong className="title">Lente</strong>
                  <p className="content">
                    {lens.name}
                    {/* |{" "}
                    {Intl.NumberFormat("pt-br", {
                      currency: "BRL",
                      style: "currency",
                    }).format(lens.price)} */}
                  </p>

                  <strong className="title">Armação</strong>
                  <p className="content">{frames}</p>

                  {switchedSecondPair.map((item, index) => (
                    <>
                      <strong className="title">{index + 2}º par</strong>
                      <p className="content">{item}</p>
                    </>
                  ))}

                  <strong className="title">Lentes de contato</strong>
                  <p className="content">
                    {switchedContactLens === ""
                      ? "Nenhuma"
                      : switchedContactLens}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="action-buttons">
          <button
            className="button-finish"
            disabled={isLoading}
            onClick={onFinish}
          >
            {isLoading ? "Salvando informações" : "Finalizar"}
          </button>

          <button disabled={isLoading} onClick={returnToDesignRecommendation}>
            Voltar para recomendações
          </button>
        </div>
      </main>
    </div>
  );
}
