import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import { DesignType } from "../../../types/Categories";
import { LensContext } from "../../../contexts/lensContext";
import PageModel from "../../../components/PageModel";
// import VSTable from "../../../components/VSTable";

import "./styles.css";
import MFTable from "../../../components/MFTable";
import ModalEndOfQuestionary from "../../../components/ModalEndOfQuestionary";

export default function DesignPage() {
  const navigate = useNavigate();

  const { switchedLens, setSwitchedLens, getIndicateLenses } =
    useContext(QuestionaryContext);

  const { fabricators, brands } = useContext(LensContext);

  const [explanationText] = useState([
    "O desenho das lentes multifocais é o que garante uma melhor transição entre a visão de longe, intermediária e perto. Quanto melhor o desenho, melhor e mais confortável será sua visão.",
    "Eyezen™️ são lentes DESENHADAS PARA QUEM TEM UMA VIDA CONECTADA que estão disponíveis para todos - use óculos ou não - para ajudar a relaxar seus olhos, prevenir doenças visuais e oferecer conforto para você se manter cada vez mais conectado.",
  ]);

  const [designs, setDesigns] = useState<DesignType[]>([]);

  const [selectedDesign, setSelectedDesign] = useState<DesignType>();

  // const [isSimpleVision, setIsSimpleVision] = useState(false);

  const [isShowModalEndOfQuestionary, setIsShowModalEndOfQuestionary] =
    useState(true);

  useEffect(() => {
    var indicateDesigns = getIndicateLenses();

    // setIsSimpleVision(indicateDesigns[0].level < 8);

    setDesigns(indicateDesigns);
    // eslint-disable-next-line
  }, [navigate]);

  function next() {
    if (selectedDesign) {
      const brand = brands.find((item) => item.id === selectedDesign.brandCod);

      setSwitchedLens({
        ...switchedLens,
        design: selectedDesign.id,
        brand: selectedDesign.brandCod,
        fabricator:
          fabricators.find((item) => item.id === brand?.fabricatorCod)?.id ??
          "",
      });
    }

    navigate("/refraction");
  }

  function previous() {
    navigate("/questionary/others-info");
  }

  return (
    <PageModel
      previous={previous}
      next={selectedDesign ? next : undefined}
      title="Lentes recomendadas"
      number={5}
    >
      {isShowModalEndOfQuestionary && (
        <ModalEndOfQuestionary
          onClose={() => setIsShowModalEndOfQuestionary(false)}
        />
      )}

      <div className="recommendation-content">
        {/* {isSimpleVision ? (
          <VSTable
            designs={designs}
            onSelect={(item) => setSelectedDesign(item)}
            selected={selectedDesign}
          />
        ) : ( */}
        <MFTable
          designs={designs}
          onSelect={(item) => setSelectedDesign(item)}
          selected={selectedDesign}
        />
        {/* )} */}

        <p className="explanation">
          {designs.length > 0 && designs[0].level < 8
            ? explanationText[1]
            : explanationText[0]}
        </p>
      </div>
    </PageModel>
  );
}
