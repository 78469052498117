import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { ResponseContext } from "../../contexts/responseContext";
import { ResponseCompleteType } from "../../types/Response";
import Header from "../Header";
import { HiCurrencyDollar } from "react-icons/hi";

import birthDateForAge from "../../utils/birthDateForAge";
import logoMBV from "../../assets/MARCA_HORIZONTAL_01.png";
import logoMercadotica from "../../assets/logo.png";

export default function DocumentPDF() {
  const { id } = useParams<{ id: string }>();

  const { getResponse } = useContext(ResponseContext);

  const [response, setResponse] = useState<ResponseCompleteType>();

  useEffect(() => {
    getResponseById();
    // eslint-disable-next-line
  }, []);

  async function getResponseById() {
    if (id) {
      const response = await getResponse(id);
      setResponse(response);
    }
  }

  return (
    <div style={styles.container}>
      <Header>
        <a href="/" style={styles.goBack}>
          Voltar para o início
        </a>
      </Header>
      <MyDocument response={response} />
    </div>
  );
}

export function MyDocument({ response }: { response?: ResponseCompleteType }) {
  const selectOptions = ["Nunca", "Às vezes", "Regularmente"];

  return (
    <React.Fragment>
      <PDFViewer style={styles.viewer}>
        <Document>
          {response && (
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Image src={logoMBV} style={styles.logo} />
                <View style={styles.row}>
                  <Text style={styles.description}>
                    Análise da visão de {response.client.name},{" "}
                    {birthDateForAge(response.client.birth)} anos
                  </Text>
                </View>

                {response.highlights.length > 0 && (
                  <>
                    <View style={styles.row}>
                      <Text style={styles.title}>Pontos Importantes</Text>
                    </View>
                    {response.highlights.map((item, index) => (
                      <View style={styles.row} key={index}>
                        <Text style={styles.textRowTitle}>{item}</Text>
                      </View>
                    ))}
                  </>
                )}

                <View style={styles.row}>
                  <Text style={styles.title}>Dioptria</Text>
                </View>
                <View style={styles.row}>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <Text style={styles.tableHeader}></Text>
                      <Text style={styles.tableHeader}>Esférico</Text>
                      <Text style={styles.tableHeader}>Cilindrico</Text>
                      <Text style={styles.tableHeader}>Eixo</Text>
                    </View>
                    <View style={styles.tableRow}>
                      <Text style={styles.tableItem}>OD</Text>
                      <Text style={styles.tableItem}>
                        {response.prescription.rightEye.esf}
                      </Text>
                      <Text style={styles.tableItem}>
                        {response.prescription.rightEye.cil === ""
                          ? "-"
                          : response.prescription.rightEye.cil}
                      </Text>
                      <Text style={styles.tableItem}>
                        {response.prescription.rightEye.eixo === ""
                          ? "-"
                          : response.prescription.rightEye.eixo}
                      </Text>
                    </View>
                    <View style={styles.tableRow}>
                      <Text style={styles.tableItem}>OE</Text>
                      <Text style={styles.tableItem}>
                        {response.prescription.leftEye.esf}
                      </Text>
                      <Text style={styles.tableItem}>
                        {response.prescription.leftEye.cil === ""
                          ? "-"
                          : response.prescription.leftEye.cil}
                      </Text>
                      <Text style={styles.tableItem}>
                        {response.prescription.leftEye.eixo === ""
                          ? "-"
                          : response.prescription.leftEye.eixo}
                      </Text>
                    </View>
                    <View style={styles.tableRow}>
                      <Text style={styles.tableItem}></Text>
                      <Text style={styles.tableItem}></Text>
                      <Text style={styles.tableItem}>Adição</Text>
                      <Text style={styles.tableItem}>
                        {response.prescription.adition === ""
                          ? "-"
                          : response.prescription.adition}
                      </Text>
                    </View>
                  </View>
                </View>

                {response.questionary && (
                  <>
                    <View style={styles.row}>
                      <Text style={styles.title}>Questionário</Text>
                    </View>

                    <View style={styles.row}>
                      <Text style={styles.textRowTitle}>
                        Tempo em atividades de perto
                      </Text>
                      <Text style={styles.textRow}>
                        {response.questionary.shortActivityTime} horas
                      </Text>
                    </View>

                    <View style={styles.row}>
                      <Text style={styles.textRowTitle}>
                        Tempo em atividades intermediárias
                      </Text>
                      <Text style={styles.textRow}>
                        {response.questionary.interActivityTime} horas
                      </Text>
                    </View>

                    <View style={styles.row}>
                      <View style={styles.rowScreenData}>
                        <View style={styles.screenDataItem}>
                          <Text style={styles.textRowTitle}>
                            Tempo diário em dispositivos móveis
                          </Text>
                          <Text style={styles.textRow}>
                            {response.questionary.mobileDeviceTime} horas
                          </Text>
                        </View>

                        <View style={styles.screenDataItem}>
                          <Text style={styles.textRowTitle}>
                            Tempo diário em dispositivos de mesa
                          </Text>
                          <Text style={styles.textRow}>
                            {response.questionary.desktopDeviceTime} horas
                          </Text>
                        </View>

                        <View style={styles.screenDataItem}>
                          <Text style={styles.textRowTitle}>
                            Tempo diário em dispositivos fixos
                          </Text>
                          <Text style={styles.textRow}>
                            {response.questionary.fixedDeviceTime} horas
                          </Text>
                        </View>
                      </View>

                      <View style={styles.totalScreenData}>
                        <Text style={styles.textRowScreenData}>
                          Tempo total de exposição à luz azul
                        </Text>
                        <Text style={styles.textRowTitle}>
                          {response.questionary.mobileDeviceTime +
                            response.questionary.desktopDeviceTime +
                            response.questionary.fixedDeviceTime}{" "}
                          horas
                        </Text>
                      </View>
                    </View>

                    <View style={styles.row}>
                      <Text style={styles.textRowTitle}>Dirige ou pilota?</Text>
                      <Text style={styles.textRow}>
                        {selectOptions[response.questionary.drive]}
                      </Text>
                    </View>

                    <View style={styles.row}>
                      <Text style={styles.textRowTitle}>
                        Usa lentes de contato?
                      </Text>
                      <Text style={styles.textRow}>
                        {selectOptions[response.questionary.useContactLenses]}
                      </Text>
                    </View>

                    <View style={styles.row}>
                      <Text style={styles.textRowTitle}>
                        Costuma fazer atividades ao ar livre?
                      </Text>
                      <Text style={styles.textRow}>
                        {selectOptions[response.questionary.outdoorActivity]}
                      </Text>
                    </View>

                    <View style={styles.row}>
                      <Text style={styles.textRowTitle}>
                        Se sente incomodado com a luz ou brilho?
                      </Text>
                      <Text style={styles.textRow}>
                        {selectOptions[response.questionary.photophobia]}
                      </Text>
                    </View>
                  </>
                )}

                <View style={styles.row}>
                  <Text style={styles.title}>Escolhas</Text>
                </View>

                <View style={styles.row}>
                  <Text style={styles.textRowTitle}>Nome</Text>
                  <Text style={styles.textRow}>{response.lens.name}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.textRowTitle}>Armação</Text>
                  <Text style={styles.textRow}>{response.frames}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.textRowTitle}>Segundo Par</Text>
                  <Text style={styles.textRow}>
                    {response.secondPair === ""
                      ? "Nenhum"
                      : response.secondPair}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.textRowTitle}>Lente de contato</Text>
                  <Text style={styles.textRow}>
                    {response.contactLens === ""
                      ? "Nenhuma"
                      : response.contactLens}
                  </Text>
                  
                </View>

                <View style={styles.row}>
                  <Text style={styles.title}>Preço</Text>
                </View>
              
                <Text style={styles.textRowPrice}> R$ {response.lens.price}</Text>
               
                

                <View style={styles.footer}>
                  <Text>Desenvolvido por </Text>
                  <Image style={styles.footerLogo} src={logoMercadotica} />
                </View>
              </View>
            </Page>
          )}
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  goBack: {
    textDecoration: "none",
    color: "#fff",
  },
  viewer: {
    width: "100vw",
    height: "calc(100vh - 80px)",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    position: "relative",
  },
  section: {
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
  },
  logo: {
    width: "auto",
    height: "50px",
    marginBottom: 20,
  },
  row: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  rowScreenData: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  screenDataItem: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    position: "relative",
  },
  totalScreenData: {
    display: "flex",
    width: "150px",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    right: 0,
  },
  description: {
    display: "flex",
    fontSize: 12,
    textAlign: "center",
  },
  title: {
    fontWeight: "bold",
    fontSize: 18,
    borderTop: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    padding: 5,
    margin: "5px 0",
    textAlign: "center",
    flex: 1,
  },
  textRowTitle: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    fontSize: 12,
    padding: 5,
  },
  textRowPrice: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    fontSize: 12,
    padding: 5,

  },
  textRow: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    fontSize: 12,
    borderLeft: "1px solid #ccc",
    padding: 5,
    paddingLeft: 20,
  },
  textRowScreenData: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    flex: 1,
    fontSize: 12,
    padding: 5,
    marginBottom: 10,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "500px",
    maxWidth: "100%",
    border: "1px solid #aaa",
    fontSize: 14,
  },
  tableRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  tableHeader: {
    backgroundColor: "#eee",
    width: "100%",
    textAlign: "center",
    border: "1px solid #aaa",
    padding: "5px 0",
  },
  tableItem: {
    backgroundColor: "#fff",
    width: "100%",
    textAlign: "center",
    border: "1px solid #aaa",
    padding: "5px 0",
  },
  footer: {
    width: "100%",
    height: "40px",
    backgroundColor: "#2d348c",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    fontSize: 10,
  },
  footerLogo: {
    width: "150px",
    height: "auto",
  },
});
