import { useState, FormEvent, useEffect, useContext } from "react"
import Header from "../../../components/Header"
import DatePicker from "../../../components/DatePicker"
import { useLocation, useNavigate } from "react-router-dom"
import Client from "../../../types/Client"
import { ClientContext } from "../../../contexts/clientContext"
import ReactInputMask from "react-input-mask"

import "./styles.css"

export default function EditClient() {
  //Variavel do react-router-dom para manipular as rotas
  const navigate = useNavigate()
  const location = useLocation()

  const { editClient } = useContext(ClientContext)

  //Valores dos inputs do formulario de cadastro
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [cpf, setCpf] = useState("")
  const [gender, setGender] = useState<"masculino" | "feminino">()
  const [day, setDay] = useState("")
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")

  //Dados de erros de cada input do formulario de cadastro
  const [nameInputError, setNameInputError] = useState(false)
  const [emailInputError, setEmailInputError] = useState(false)
  const [phoneInputError, setPhoneInputError] = useState(false)
  const [genderInputError, setGenderInputError] = useState(false)
  const [dayInputError, setDayInputError] = useState(false)
  const [monthInputError, setMonthInputError] = useState(false)
  const [yearInputError, setYearInputError] = useState(false)

  //Executa uma funcao que busca o cliente selecionado para edicao
  useEffect(() => {
    //Verifica se algum dado do cliente foi mandado via navigate
    if (!location.state) {
      navigate("")
      return
    }

    //Pega o objeto do cliente que sera editado
    const client = location.state as Client

    //Passa cada atributo do cliente para as variavel de estado
    setId(client.id)
    setName(client.name)
    setEmail(client.email)
    setPhone(client.phone)
    setCpf(client.cpf)
    setGender(client.gender)
    setDay(client.birth.split("-")[2])
    setMonth(client.birth.split("-")[1])
    setYear(client.birth.split("-")[0])
  }, [location.state, navigate])

  //Aplica as edicoes
  function onEdit(e: FormEvent) {
    e.preventDefault()

    //Verificar os campos vazios e armazenar no state
    setNameInputError(name.trimEnd().trimStart() === "")
    setGenderInputError(gender === undefined)
    setDayInputError(day === "")
    setMonthInputError(month === "")
    setYearInputError(year === "")

    //Se tiver algum campo vazio, saia da funcao
    if (
      name.trimEnd().trimStart() === "" ||
      phone.trimEnd().trimStart() === "" ||
      gender === undefined ||
      day === "" ||
      month === "" ||
      year === ""
    ) {
      return
    }

    editClient({
      id,
      name,
      email,
      phone,
      cpf,
      gender,
      birth: `${year}-${month}-${day}`,
    })
      .then((res) => {
        alert("Editado realizado com sucesso!")
        navigate("", { state: null })
      })
      .catch((err) => {
        if (err.code === 403) {
          alert("CPF ou email está em uso por outro cliente")
        }
        alert("Erro ao editar dados, verifique os dados e tente novamente")
      })
  }

  //Cancela a edicao, volta para home
  function cancel(e: FormEvent) {
    e.preventDefault()

    navigate("", { state: null })
  }

  return (
    <div className="edit-page">
      <Header />
      <main>
        <form onSubmit={onEdit}>
          <div className="input-box name">
            <label>Nome</label>
            <input
              type="text"
              className={nameInputError ? "input-error" : ""}
              value={name}
              onChange={(event) => setName(event.target.value)}
              onFocus={() => setNameInputError(false)}
            />
          </div>

          <div className="input-box gender">
            <label>Sexo</label>
            <select
              className={genderInputError ? "input-error" : ""}
              value={gender}
              onChange={(event) => setGender(event.target.value as any)}
              onFocus={() => setGenderInputError(false)}
            >
              <option value="none" hidden>
                Selecione
              </option>
              <option value="masculino">Masculino</option>
              <option value="feminino">Feminino</option>
            </select>
          </div>

          <div className="input-box email">
            <label>Email</label>
            <input
              type="email"
              className={emailInputError ? "input-error" : ""}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onFocus={() => setEmailInputError(false)}
            />
          </div>

          <div className="input-box phone">
            <label>Celular</label>
            <ReactInputMask
              mask="(99) 99999-9999"
              type="phone"
              className={phoneInputError ? "input-error" : ""}
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              onFocus={() => setPhoneInputError(false)}
            />
          </div>

          <div className="input-box cpf">
            <label>CPF</label>
            <ReactInputMask
              mask="999.999.999-99"
              type="text"
              value={cpf}
              disabled
            />
          </div>

          <div className="input-box birth">
            <label>Data de Nascimento</label>
            <DatePicker
              day={day}
              month={month}
              year={year}
              setDay={setDay}
              setMonth={setMonth}
              setYear={setYear}
              dayInputError={dayInputError}
              monthInputError={monthInputError}
              yearInputError={yearInputError}
              setDayInputError={setDayInputError}
              setMonthInputError={setMonthInputError}
              setYearInputError={setYearInputError}
            />
          </div>

          <div className="actions-button">
            <button className="button-cancel" onClick={cancel}>
              Cancelar
            </button>
            <button className="button-confirm" type="submit">
              Confirmar
            </button>
          </div>
        </form>
      </main>
    </div>
  )
}
